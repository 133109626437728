import { createTheme } from "@mui/material/styles";
import type {} from "@mui/x-data-grid/themeAugmentation";

export const theme = createTheme({
  layout: {
    // Layout colors
    light: "#2774AE",
    main: "#005587",
    dark: "#003B5C",
    contrastText: "#fff",
  },

  palette: {
    primary: {
      // Interactive colors - light background
      light: "#0079BF",
      main: "#00598C",
      dark: "#003B5C",
      contrastText: "#fff",
    },
    secondary: {
      // Interactive colors - dark background
      light: "#FFD100",
      main: "#FFD100",
      dark: "#FFBF0F",
      contrastText: "#fff",
    },

    error: {
      main: "#D60000",
    },
    success: {
      main: "#007339",
    },
    warning: {
      main: "#F57F17",
    },
    info: {
      light: "#e6f2ff",
      main: "#8BB8E8",
    },
    grey: {
      100: "#E5E5E5",
      400: "#999999",
      600: "#666666",
      800: "#333333",
    },
  },
  typography: {
    fontFamily: "Helvetica, Arial, sans-serif",
    fontWeightBold: 600,
    h1: {
      fontWeight: 700,
      fontSize: "48px",
      lineHeight: "55px",
    },
    h2: {
      fontWeight: 700,
      fontSize: "36px",
      lineHeight: "41px",
    },
    h3: {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "28px",
    },
    h4: {
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "24px",
    },
    h5: {
      fontWeight: 700,
      fontSize: "16x",
      lineHeight: "18px",
    },
    h6: {
      fontWeight: 700,
      fontSize: "13px",
      lineHeight: "15px",
    },
    subtitle1: {
      // Lead Copy
      fontWeight: 400,
      fontSize: "24px",
      lineHeight: "30px",
    },
    body1: {
      // Body Copy
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "30px",
    },
    body2: {
      // Legal Copy
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "18px",
    },
    button: {
      fontWeight: 700,
      // fontSize: "14px",
      // lineHeight: "32px",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // style outlined buttons
          // "&.MuiButton-outlined, &.MuiButton-outlined:hover": {
          //   backgroundColor: "#fff",
          //   borderWidth: 2,
          // },
          // make startIcon bigger
          // ".MuiButton-startIcon": {
          //   svg: {
          //     fontSize: 30,
          //   },
          // },
        },
        // root: ({ ownerState }) => ({
        //   ...(ownerState.variant === 'outlined' &&
        //     ownerState.color === 'primary' && {
        //       backgroundColor: '#fff',
        //       borderWidth: 2,
        //       '&:hover': {
        //         borderWidth: 2,
        //         backgroundColor: '#fff',
        //       },
        //     }),
        // }),
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          ".MuiTableCell-head": {
            fontWeight: 600,
          },
          ".MuiTableCell-sizeMedium": {
            fontSize: 14,
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          ".MuiButton-startIcon svg": { fontSize: "18px !important" },
        },
        cell: {
          fontSize: 14,
        },
        columnHeader: {
          fontSize: 14,
        },
        columnHeaderTitle: {
          fontSize: 14,
          fontWeight: "bold",
        },
      },
    },
  },
});
