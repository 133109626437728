import React from 'react'

import ReactDOM from "react-dom/client";
import { localStorageLoggedInUser } from './components/authorization/Utilities'
import { doPing } from './components/utilities/Api'

import { 
  // BrowserRouter, Routes, Route 
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from "react-router-dom";
import {
	ApolloClient,
	InMemoryCache,
	ApolloProvider,
	HttpLink,
	ApolloLink,
	concat,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";

import "./index.css";
import reportWebVitals from "./reportWebVitals";
import buildMetadata from "./build-metadata.json";

import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./themes/default";

import Layout, { AdminLayout } from "./pages/Layout";
import Home from "./pages/home/Home";
import {Event, EventDetail, EventRegistrationTicketSelection, EventRegistrationAttendeeInformation, EventRegistrationReviewOrder, EventRegistrationConfirmOrder} from './pages/event/Event';
import { AuthContext, AuthProvider } from './components/authorization/AuthContext';

// import NewUser from "./pages/admin/user/NewUser";
// import NewUserBulk from "./pages/admin/user/NewUserBulk";
// import ProgramParticipation from "./pages/participation/ProgramParticipation";
// import NewParticipation from "./pages/participation/NewParticipation";
// import EditParticipation from "./pages/participation/EditParticipation";
// import NotesTestBank from "./pages/bank/NotesTestBank";
// import ClassDetail from "./pages/bank/ClassDetail";
// import NewDocument from "./pages/bank/NewDocument";
// import PersonalProfile from "./pages/profile/PersonalProfile";

// Public pages
// import { MLLVoting } from './pages/mll/MLLVoting';

// Admin pages
// import Admin from "./pages/admin/Admin";
// import Users from "./pages/admin/user/Users";
// import UserDetail from "./pages/admin/user/UserDetail";
// import BulkManagement from "./pages/admin/participation/BulkManagement";
// import ParticipationProfiles from "./pages/admin/participation/AllProfiles";
// import ParticipationProfile from "./pages/admin/participation/IndividualProfile";
// import { default as AdminSubmissionDetails } from "./pages/admin/participation/SubmissionDetails";
// import { default as AdminNewParticipation } from "./pages/admin/participation/NewParticipation";
// import AdminSettings from "./pages/admin/settings/Settings";
// import NewAcademicYear from "./pages/admin/settings/NewAcademicYear";
// import EditAcademicYear from "./pages/admin/settings/EditAcademicYear";
// import BankManagement from "./pages/admin/bank/BankManagement";
// import DocumentReview from "./pages/admin/bank/DocumentReview";
// import NewClass from "./pages/admin/bank/NewClass";
// import { MLLManagment } from './pages/admin/mll/MLLManagement';

import LoginLandingPageDemo from "./pages/temp_demo/login_demo/LoginLandingPageDemo";
import GraphQlDemo from "./pages/temp_demo/login_demo/GraphQlDemo";
import PersonalizedLinkDemo from "./pages/temp_demo/login_demo/PersonalizedLinkDemo";

import { Toast } from "./components/alerts/Toaster";

// import RenewalRequirements from "./pages/participation/RenewalRequirements";
import ErrorPage from "./pages/error/error";

// import { GetAccessRights } from './components/authorization/GetAccessRights'
import { GoToLogout } from "./components/authorization/LoginLogout";

// import { PageState } from './components/authorization/PageState'
// import NewFacultyBulk from './pages/admin/mll/NewFacultyBulk';

// you can set this in .env.local - which is ignore by git
if (process.env.REACT_APP_HIDE_CONSOLE_WARN) console.warn = () => {};

// use this to make sure builds match and verify updates are actually pushed, we will make it more formal eventually
// build # is only generated on npm build (eventually will only be incremented on a push to stage branch or something)
console.log("build environment: " + buildMetadata.build_environment);
console.log("build date: " + buildMetadata.date);
console.log("build ID: " + buildMetadata.build_id);
console.log("github commit ID: " + buildMetadata.commit_id);
console.log("react graphql: " + process.env.REACT_APP_GRAPHQL_URL);
console.log("aws environment: " + process.env.REACT_APP_AWS_ENVIRONMENT);
console.log("localStorageLoggedInUser: ", localStorageLoggedInUser());

// called when any user loads home page, to wake up the lamdba
if (process.env.REACT_APP_AWS_ENVIRONMENT !== "local") doPing();

// https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
const retryLink = new RetryLink(); // retries 5 times by default on network and server errors
const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL });
const link = concat(retryLink, httpLink);

const authMiddleware = new ApolloLink((operation, forward) => {
	// MATT - Below is the block to add the auth to the headers
	operation.setContext(({ headers = {} }) => ({
		headers: {
			...headers,
			authorization: localStorage.getItem("uclaeaidmtoken") || 'Token To Hello World',
		},
	}));

	return forward(operation);
});

export default function App() {

  const client = new ApolloClient({
      uri: process.env.REACT_APP_GRAPHQL_URL,
      cache: new InMemoryCache(),
      link: concat(authMiddleware, link),
  });

  type RouteConfig = {
    path: string;
    element: React.ReactElement;
    indexElement?: React.ReactElement;
    errorElement?: React.ReactElement;
    children?: RouteConfig[];
  };

  const routes: RouteConfig[] = [
    { path: "/", element: <Layout />, indexElement: <Home />, errorElement: <ErrorPage /> },
    { path: "/logout", element: <GoToLogout /> },
    { path: "/login_demo", element: <LoginLandingPageDemo /> },
    { path: "/graphql_demo", element: <GraphQlDemo /> },
    { path: "/public_id_demo", element: <PersonalizedLinkDemo /> },

    { path: "/event", element: <Event />},
    { path: "/event/:id", element: <EventDetail />},
    // { path: "/event/:id/registration1", element: <EventRegistration />},
    // { path: "/event/:id/registration2", element: <EventAttendeeInformation />},
    { path: "/event/:id", element: <EventDetail />},
          // children: [
          //   { path: "attendee_information", element: <EventRegistrationAttendeeInformation /> },
          //   { path: "ticket_selection", element: <EventRegistrationTicketSelection /> },
          //   { path: "review_order", element: <EventRegistrationTicketSelection /> },
          //   { path: "order_confirmation", element: <EventRegistrationConfirmOrder /> }
          // ]
        // }
    { path: "/event/:id/attendee_information", element: <EventRegistrationAttendeeInformation /> },
    { path: "/event/:id/ticket_selection", element: <EventRegistrationTicketSelection /> },
    { path: "/event/:id/review_order", element: <EventRegistrationReviewOrder /> },
    { path: "/event/:id/order_confirmation", element: <EventRegistrationConfirmOrder /> }
  ];
  
  // const router = (isLoading: boolean, accessRights: any) => { 
  const router = () => { 
    // if (!isLoading && hasData(accessRights)) {
    //   console.log('TEST: createRouter...', accessRights)
    // }
  
    // return createBrowserRouter(
    //   createRoutesFromElements(
    //     <>
    //       ... existing routes ...
    //     </>
    //   )
    // );
  
    return createBrowserRouter(
      createRoutesFromElements(
        routes.map(route => (
          <Route 
            path={route.path} 
            element={route.element} 
            errorElement={route.errorElement}
          >
            {route.indexElement && <Route index element={route.indexElement}/>}
            {/* Supports Children Content (<Outlet />) */}
            {route.children && Array.isArray(route.children) && route.children.map((child: any) => (
              <Route path={child.path} element={child.element}>
                {child.children && Array.isArray(child.children) && child.children.map((grandchild: any) => (
                  <Route path={grandchild.path} element={grandchild.element} />
                ))}
              </Route>
            ))}
          </Route>
        ))
      )
    );
  }

  return (
      <React.StrictMode>
          <ApolloProvider client={client}>
            <AuthProvider>
            {/* <GetAccessRights> */}
              {/* {({isLoading, accessRights}: any) => ( */}
                <ThemeProvider theme={theme}>
                  {/* <RouterProvider router={router(isLoading, accessRights)} /> */}
                  <RouterProvider router={router()} />
                  <Toast />
                </ThemeProvider>
              {/* )} */}
            {/* </GetAccessRights> */}
            </AuthProvider>
          </ApolloProvider>
      </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
