import { gql } from "@apollo/client";
import { ResultType } from "@remix-run/router/dist/utils";

export const CREATE_REGISTRATION = gql`
  mutation saveRegistration($registration: CreateRegistrationInput!) {
    saveRegistration(registration: $registration) {
      successful
      message
      error
      inserted_id
    }
  }
`;

// Postman example:
// QUERY
  // mutation saveRegistration($registration: CreateRegistrationInput!) {
  //   saveRegistration(registration: $registration) {
  //     successful
  //     message
  //     error
  //     inserted_id
  //   }
  // }

// GRAPHQL VARIABLES:
// {
//   "registration": {
//     "CrmId": "1111111111",
//     "Email": "test2@test.com",
//     "Phone": "+13105551212",
//     "FirstName": "Two",
//     "LastName": "Bruin",
//     "Guests": [
//       {"FirstName": "Guest", "LastName": "NumberOne"},
//       {"FirstName": "Guest", "LastName": "NumberTwo"}
//     ]
//   }
// }

// SUCCESSFUL RESULT
// {
//   "data": {
//     "saveRegistration": {
//       "successful": true,
//       "message": "Successfully saved registration for test2@test.com",
//       "error": null,
//       "inserted_id": "5cca2d59-6f31-4523-bdfd-0dc10548088f"
//     }
//   }
// }

// ERROR RESULT
// {
//     "errors": [
//         {
//             "message": "ValidationException: One or more parameter values were invalid: Missing the key RegistrationId in the item",
//             "locations": [
//                 {
//                     "line": 2,
//                     "column": 5
//                 }
//             ],
//             "path": [
//                 "saveRegistration"
//             ]
//         }
//     ],
//     "data": {
//         "saveRegistration": null
//     }
// }