import {
  AppBar,
  Box,
  Button,
  Modal,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";

import { useAuth } from "../../components/authorization/AuthContext";

import {
  doSignout,
  windowPopupCidmLogin,
} from "pages/temp_demo/login_demo/LoginLandingPageDemo";

export interface EventRegProfile {
  constituentLookupId: string;
  emails: string[];
  phones: string[];
  names: string[];
  addresses: string[];
}

type HeaderProps = {
  title?: string;
  hideProfileButton?: string;
  hideSignInButton?: string;
  hideSignOutButton?: string;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#FFCBCB",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const handleEditProfile = (setIsLoggedIn: any, setLsProfile: any) => {
  console.log("Handle Edit Profile");
  windowPopupCidmLogin("profile", setIsLoggedIn, setLsProfile);
};

export const UnableToLocateLoginInfo = () => { 
  
  localStorage.removeItem("uclacidmdata");
  localStorage.removeItem("uclaeaidmtoken");
  localStorage.removeItem("uclaeventregprofile");

  return (
    <div
      style={{
        backgroundColor: "#FFCBCB",
        fontWeight: "bold",
        border: "none",
        padding: 30,
        margin: 25,
      }}
    >
      <p>
        We were unable to locate your information in our system. Please
        call (310) 794-2447 for assistance.
        <br />
      </p>
      <p>
        You may sign in with a different credentials, or complete this
        transaction without signing in.
      </p>
    </div>
  );
}

export const Header = ({
  title = "Website Title",
  hideProfileButton = "false",
  hideSignInButton = "false",
  hideSignOutButton = "false",
}: HeaderProps) => {
  const { id } = useParams();
  const { isLoggedIn, setIsLoggedIn, setLsProfile } = useAuth();

  const handleSignIn = () => {
    console.log("Handle Sign In Window Popup");
    // window.location.href = "/signin";
    windowPopupCidmLogin("signin", setIsLoggedIn, setLsProfile);
  };

  const handleSignOut = () => {
    console.log("Handle Signing out");
    doSignout(setIsLoggedIn, setLsProfile, `/event/${id}`);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        {isLoggedIn && hideProfileButton === "false" && (
          <Button
            color="inherit"
            onClick={() => handleEditProfile(setIsLoggedIn, setLsProfile)}
          >
            Edit Profile
          </Button>
        )}
        {isLoggedIn === true && hideSignOutButton === "false" && (
          <div>
            <Button color="inherit" onClick={handleOpen}>
              Sign Out
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography variant="h4" mb={4}>
                  Are you sure you want to sign out and lose your current
                  progress?
                </Typography>
                <Stack direction="row" spacing={2}>
                  <Button variant="contained" onClick={handleSignOut}>
                    Yes
                  </Button>
                  <Button variant="outlined" onClick={handleClose}>
                    No
                  </Button>
                </Stack>
              </Box>
            </Modal>
          </div>
        )}
        {isLoggedIn === false && hideSignInButton === "false" && (
          <Button color="inherit" onClick={handleSignIn}>
            Sign In
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

// const RegistrationStepRedirection = () => {
//   const { id } = useParams();

//   const handleRegistrationStep1 = () => {
//     // Redirect to registration step 1
//     console.log(`Redirecting to registration step 1: ${id}`);
//     window.location.href = `/event/${id}/registration1`;
//   };

//   const handleRegistrationStep2 = () => {
//     // Redirect to registration step 2
//     console.log(`Redirecting to registration step 2: ${id}`);
//     window.location.href = `/event/${id}/registration2`;
//   };

//   return (
//     <div>
//       <button onClick={handleRegistrationStep1}>Registration Step 1</button>
//       <button onClick={handleRegistrationStep2}>Registration Step 2</button>
//     </div>
//   );
// };

export const Event = () => {
  return (
    <div>
      <Header title="Events Home Page" />
      <div>Featured Event</div>
      <img src="https://source.unsplash.com/aEnH4hJ_Mrs" alt="" width="800" />
      <div>
        <a href={"/event/123/attendee_information"}>Register for this event</a>
      </div>
      <div>Events Banner</div>
      <div>Events Calendar</div>
    </div>
  );
};

//Event Detail / Individual Event Landing Page
export const EventDetail = () => {
  const { id } = useParams();
  const { isLoggedIn, lsProfile } = useAuth();
  const [isProfileInfoMissing, setIsProfileInfoMissing] = useState(false);
  const [triedLoggingIn, setTriedLoggingIn] = useState(false);

  useEffect(() => {
    const uclaCidmData = localStorage.getItem("uclacidmdata");
    // console.debug("uclaCidmData: ", uclaCidmData)
    if (uclaCidmData) {
      // && JSON.parse(uclaCidmData)["success"]) {
      setTriedLoggingIn(true);
    }
    // console.debug("isProfileInfoMissing: ", isProfileInfoMissing)
    // console.debug("CIDM DATA: ", uclaCidmData?.constituentLookupId)

  }, [isLoggedIn, triedLoggingIn, lsProfile]);

  console.debug(isProfileInfoMissing === true && triedLoggingIn === true)
  console.debug("triedLoggingIn: ", triedLoggingIn)
  console.debug("isLoggedIn: ", isLoggedIn)
  console.debug("isProfileInfoMissing: ", isProfileInfoMissing)

  // const isChildView = false; // Declare the isChildView variable

  // return <h1>Event Detail Page for event {id}</h1>;
  if (isLoggedIn === undefined) {
    return <div></div>;
  }

  return (
    <div>
      <Header title={`Event - ${id} - Landing Page`} />
      <Box padding={2}>
        <img src="https://source.unsplash.com/nwLTVwb7DbU" alt="" width="400" />

        <AttendeeProfileBlock
          showWelcome="1"
          showAttendeeInfo="false"
          showForm="false"
          setIsProfileInfoMissing={setIsProfileInfoMissing}
        />
        <div style={{ margin: 20 }}>
          Placeholer for {id} event details below
        </div>
        <div>&nbsp;</div>

        {(isLoggedIn === false && isProfileInfoMissing === true && triedLoggingIn === true) && (
          <UnableToLocateLoginInfo />
        )}

        {/* {!isChildView && ( */}
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            color="primary"
            href={`/event/${id}/attendee_information`}
          >
            Register for this event
          </Button>
        </Stack>
        {/* )} */}
      </Box>
      <Outlet />
    </div>
  );
};

export const EventRegistrationAttendeeInformation = () => {
  const { id } = useParams();
  const { isLoggedIn, setIsLoggedIn, setLsProfile } = useAuth();
  // const [attendeeInfo, setAttendeeInfo] = useState<any>({});
  const [isProfileInfoMissing, setIsProfileInfoMissing] = useState(false);

  // Scaffolding for when we have a form to fill out
  // const emptyProfile: EventRegProfile = {
  //   constituentLookupId: "",
  //   emails: [],
  //   phones: [],
  //   names: [],
  //   addresses: []
  // };

  if (isLoggedIn === undefined) {
    return <div></div>;
  }

  return (
    <div>
      <Header title={`Event Registration Attendee Information for ${id}`} />

      <Box padding={2}>
        <h1>Attendee Information</h1>
        <AttendeeProfileBlock
          showWelcome="1"
          setIsProfileInfoMissing={setIsProfileInfoMissing}
          showForm="true"
        />
        <div>&nbsp;</div>
        <div>
          <Stack direction="row" spacing={2}>
            {isLoggedIn && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleEditProfile(setIsLoggedIn, setLsProfile)}
              >
                Edit Profile
              </Button>
            )}
            <Button variant="contained" color="primary" href={`/event/${id}`}>
              Back to Event Details
            </Button>

            {!isProfileInfoMissing && (
              <Button
                variant="contained"
                color="primary"
                href={`/event/${id}/ticket_selection`}
              >
                Continue to Ticket Selection
              </Button>
            )}
          </Stack>
        </div>
      </Box>
    </div>
  );
};

export const EventRegistrationTicketSelection = () => {
  const { id } = useParams();
  const { isLoggedIn } = useAuth();
  // const [promotionCode, setPromotionCode] = useState("");
  // const [ticketSelection, setTicketSelection] = useState("Make a Selection");
  // const [guestTicketSelection, setGuestTicketSelection] =
  //   useState("Make a Selection");

  // const handlePromotionCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   setPromotionCode(event.target.value);
  // };

  // const handleTicketSelectionChange = (event: SelectChangeEvent<string>) => {
  //   setTicketSelection(event.target.value as string);
  // };

  // const handleGuestTicketSelectionChange = (
  //   event: SelectChangeEvent<string>
  // ) => {
  //   setGuestTicketSelection(event.target.value as string);
  // };

  // const handleSubmit = (event: any) => {
  //   // Redirect to registration2
  //   event?.preventDefault();
  //   console.log(`Redirecting to registration2: ${id}`);
  //   window.location.href = `/event/${id}/registration2`;
  // };
  if (isLoggedIn === undefined) {
    return <div></div>;
  }
  return (
    <div>
      <Header
        title={`Event Registration Ticket Selection for ${id}`}
        hideProfileButton="true"
        hideSignInButton="true"
        hideSignOutButton="true"
      />
      <Box p={6}>
        <h1>Welcome!</h1>

        <div>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="primary"
              href={`/event/${id}/attendee_information`}
            >
              Back to Attendee Information
            </Button>

            <Button
              variant="contained"
              color="primary"
              href={`/event/${id}/review_order`}
            >
              Continue to Review Order
            </Button>
          </Stack>
        </div>

        {/* 
        
        <form onSubmit={(event) => handleSubmit(event)}>
          <Stack spacing={4}>
            <FormControl fullWidth>
              <TextField
                label="Promotion Code"
                value={promotionCode}
                onChange={handlePromotionCodeChange}
              />
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Ticket</InputLabel>
              <Select
                value={ticketSelection}
                onChange={handleTicketSelectionChange}
              >
                <MenuItem value="Make a Selection">Make a Selection</MenuItem>
                <MenuItem value="Ticket Option 1">Ticket Option 1</MenuItem>
                <MenuItem value="Ticket Option 2">Ticket Option 2</MenuItem>
                <MenuItem value="Ticket Option 3">Ticket Option 3</MenuItem>
              </Select>
              <Typography variant="caption">
                Free ticket for main registrant.
              </Typography>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Guest Ticket $10</InputLabel>
              <Select
                value={guestTicketSelection}
                // onChange={(event) => { handleGuestTicketSelectionChange(event) }}
                onChange={handleGuestTicketSelectionChange}
              >
                <MenuItem value="Make a Selection">Make a Selection</MenuItem>
                <MenuItem value="Guest Ticket Option 1">
                  Guest Ticket Option 1
                </MenuItem>
                <MenuItem value="Guest Ticket Option 2">
                  Guest Ticket Option 2
                </MenuItem>
                <MenuItem value="Guest Ticket Option 3">
                  Guest Ticket Option 3
                </MenuItem>
              </Select>
              <Typography variant="caption">
                $10 dollars for additional guests.
              </Typography>
            </FormControl>

            <Button type="submit" variant="contained" color="primary">
              Next
            </Button>
          </Stack>
        </form> */}
      </Box>
    </div>
  );
};

export const EventRegistrationReviewOrder = () => {
  const { id } = useParams();
  const { isLoggedIn } = useAuth();
  const [isProfileInfoMissing, setIsProfileInfoMissing] = useState(false);

  if (isLoggedIn === undefined) {
    return <div></div>;
  }
  return (
    <div>
      <Header
        title={`Event Review Order for ${id}`}
        hideProfileButton="true"
        hideSignInButton="true"
        hideSignOutButton="true"
      />

      <Box padding={2}>
        <h1>Review Order for Event {id}</h1>

        <AttendeeProfileBlock
          setIsProfileInfoMissing={setIsProfileInfoMissing}
          showForm="false"
        />

        <div>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="primary"
              href={`/event/${id}/ticket_selection`}
            >
              Back to Ticket Selection
            </Button>

            <Button
              variant="contained"
              color="primary"
              href={`/event/${id}/order_confirmation`}
            >
              Confirm Order
            </Button>
          </Stack>
        </div>
      </Box>
    </div>
  );
};

export const EventRegistrationConfirmOrder = () => {
  const { id } = useParams();
  const [isProfileInfoMissing, setIsProfileInfoMissing] = useState(false);

  return (
    <div>
      <Header
        title={`Event Confirm Order for ${id}`}
        hideProfileButton="true"
        hideSignInButton="true"
      />

      <Box padding={2}>
        <h1>Order Confirmation for Event {id}</h1>
        <AttendeeProfileBlock
          setIsProfileInfoMissing={setIsProfileInfoMissing}
          showForm="false"
        />
      </Box>
    </div>
  );
};

export const AttendeeProfileBlock = ({
  showWelcome = "0",
  setIsProfileInfoMissing,
  showAttendeeInfo = "true",
  showForm = "false",
}: any) => {
  // const { id } = useParams();
  const { isLoggedIn, lsProfile } = useAuth();
  const [attendeeInfo, setAttendeeInfo] = useState<any>({});

  useEffect(() => {
    if (lsProfile) {
      const parsedInfo = JSON.parse(lsProfile);
      const { constituentLookupId, emails, phones, names, addresses } =
        parsedInfo;

      const name =
        names.find((name: any) => name.nameType === "Primary") ||
        names[0] ||
        "";
      const address =
        addresses.find((address: any) => address.isPrimary === true) ||
        addresses[0] ||
        "";
      const phone =
        phones.find((phone: any) => phone.isPrimary === true) ||
        phones[0] ||
        "";
      const email =
        emails.find((email: any) => email.isPrimary === true) ||
        emails[0] ||
        "";

      const attendeeProfile = {
        id: constituentLookupId,
        title: name?.title,
        firstName: name?.firstName,
        // firstName: "",
        lastName: name?.keyName,
        middleName: name?.middleName,
        address1: address?.addressBlock,
        adddressCity: address?.city,
        addressState: address?.state,
        addressZip: address?.postCode,
        addressCountry: address?.country,
        phoneNumber: phone?.number,
        phoneType: phone?.phoneType,
        emailAddress: email?.emailAddress,
        emailType: email?.emailAddressType,
      };

      if (
        !attendeeProfile.firstName ||
        !attendeeProfile.lastName ||
        !attendeeProfile.emailAddress ||
        !attendeeProfile.phoneNumber ||
        !attendeeProfile.address1 ||
        !attendeeProfile.adddressCity ||
        !attendeeProfile.addressState ||
        !attendeeProfile.addressZip
      ) {
        setIsProfileInfoMissing(true);
      } else {
        setIsProfileInfoMissing(false);
      }

      console.log(`parsed attendee Profile`, attendeeProfile);
      setAttendeeInfo(attendeeProfile);
    }
  }, [lsProfile, setAttendeeInfo, setIsProfileInfoMissing]);

  if (isLoggedIn === undefined) {
    return <div></div>;
  }

  return (
    <div>
      <Box padding={2}>
        <div>
          {showWelcome && showWelcome === "1" && (
            <h3>
              Welcome!{" "}
              {isLoggedIn === true && (
                <>
                  {attendeeInfo.title} {attendeeInfo.lastName}
                </>
              )}
            </h3>
          )}
          {/* Going to need to handle which page it's on. Form is only displayed on Attendee Information Page. */}
          {/* Object.keys(attendeeInfo).length === 0 */}
          {isLoggedIn === false && showForm === "true" && (
            <div>
              <h3 style={{ color: "red" }}>
                FIELDS FOR MANUAL ATTENDEE INFO WILL APPEAR HERE
              </h3>
            </div>
          )}

          {(isLoggedIn === true || showForm === "false") &&
            showAttendeeInfo === "true" && (
              <>
                <div>
                  <span
                    style={{ color: attendeeInfo.firstName ? "black" : "red" }}
                  >
                    <strong>First Name:</strong> {attendeeInfo.firstName || "?"}
                  </span>
                </div>
                <div>
                  <span
                    style={{ color: attendeeInfo.lastName ? "black" : "red" }}
                  >
                    <strong>Last Name:</strong> {attendeeInfo.lastName || "?"}
                  </span>
                </div>
                <div>
                  <span
                    style={{
                      color: attendeeInfo.emailAddress ? "black" : "red",
                    }}
                  >
                    <strong>Email:</strong> {attendeeInfo.emailAddress || "?"}
                  </span>
                </div>
                <div>
                  <span
                    style={{
                      color: attendeeInfo.phoneNumber ? "black" : "red",
                    }}
                  >
                    <strong>Phone:</strong> {attendeeInfo.phoneNumber || "?"}
                  </span>
                </div>
                <div>
                  <span
                    style={{ color: attendeeInfo.address1 ? "black" : "red" }}
                  >
                    <strong>Address:</strong> {attendeeInfo.address1 || "?"}
                  </span>
                </div>
                <div>
                  <span
                    style={{
                      color: attendeeInfo.adddressCity ? "black" : "red",
                    }}
                  >
                    <strong>City:</strong> {attendeeInfo.adddressCity || "?"}
                  </span>
                </div>
                <div>
                  <span
                    style={{
                      color: attendeeInfo.addressState ? "black" : "red",
                    }}
                  >
                    <strong>State:</strong> {attendeeInfo.addressState || "?"}
                  </span>
                </div>
                <div>
                  <span
                    style={{ color: attendeeInfo.addressZip ? "black" : "red" }}
                  >
                    <strong>Zip:</strong> {attendeeInfo.addressZip || "?"}
                  </span>
                </div>
              </>
            )}
        </div>
      </Box>
    </div>
  );
};

export default Event;
