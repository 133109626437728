import { gql } from "@apollo/client";
import { ResultType } from "@remix-run/router/dist/utils";

// can be used to find a registration by crm idor email
// (the two things the site needs to check to make sure a duplicate registration doesn't already exist)
// To find a query by ID the back end needs to make a db.get call instead of db.query.
// This could be a separate graphql query, or the back end could just handle registration id differently than crm id or email.
export const LOOKUP_REGISTRATION = gql`
  query lookupRegistration($crm_id: String, $email: String) {
    lookupRegistration(crm_id: $crm_id, email: $email) {
      RegistrationId
      CrmId
      Email
      FirstName
      LastName
    }
  }`;

// Postman example:
// QUERY
  // query lookupRegistration($crm_id: String, $email: String) {
  //   lookupRegistration(crm_id: $crm_id, email: $email) {
  //     RegistrationId
  //     CrmId
  //     Email
  //     FirstName
  //     LastName
  //     Guests {
  //       FirstName
  //       LastName
  //     }
  //   }
  // }

// VARIABLES
// {
//   "crm_id": "1111111111"
// }

// SUCCESS RESULTS
// {
//   "data": {
//     "lookupRegistration": {
//       "RegistrationId": "5cca2d59-6f31-4523-bdfd-0dc10548088f",
//       "CrmId": "1111111111",
//       "Email": "test2@test.com",
//       "FirstName": "Two",
//       "LastName": "Bruin",
//       "Guests": [
//         {
//           "FirstName": "Guest",
//           "LastName": "NumberOne"
//         },
//         {
//           "FirstName": "Guest",
//           "LastName": "NumberTwo"
//         }
//       ]
//     }
//   }
// }

// NO REGISTRATION FOUND RESULTS
// {
//   "data": {
//     "lookupRegistration": null
//   }
// }

// ERROR RESULT
// {
//   "errors": [
//         {
//             "message": "ValidationException: ExpressionAttributeValues must not be empty",
//             "locations": [
//                 {
//                     "line": 2,
//                     "column": 5
//                 }
//             ],
//             "path": [
//                 "lookupRegistration"
//             ]
//         }
//     ],
//     "data": {
//         "lookupRegistration": null
//     }
// }
