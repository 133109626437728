import { Box, Button, Typography } from "@mui/material";
import React from "react";
import styled from 'styled-components';
import { theme } from "../../themes/default";

import { doLogin } from "../../components/authorization/Utilities";
import { getReturnPage } from "../../components/authorization/LoginLogout";
import LayoutContainer from "../../components/container/Container";
import GlobalNav from "../../components/navigation/GlobalNav";

import welcome from '../../images/event-placeholder.png'

const StyledHeader: any = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	maxHeight: 464px;
	background-color: ${theme.palette.primary.main};
	overflow: hidden;
	&:before {
		content: '';
		position: absolute;
		left: 0;
		height: 100%;
		width: 100%;
		background: url(${welcome}) center / cover;
		filter: blur(50px);
		z-index: 0;
	}
	img {
		z-index: 1;
	}
`;

const Home = () => {

  return (
		<>
			<GlobalNav />
			<StyledHeader>
        <img src={welcome} alt="Welcome to Events RSVP!!!"/>
      </StyledHeader>
			<LayoutContainer>
				<Typography variant="h1" mb={4}>Welcome to Events RSVP!!!</Typography>
				<Box mb={4}>
          <h2><a href="/event/CIDM_POC">See CIDM POC (EVMT-21)</a></h2>
          <h2><a href="/login_demo">See login demo here</a></h2>
          <h2><a href="/public_id_demo?pid=42EDE97E-3767-49F7-96CE-998DCC331CA7">See personalized link demo here</a></h2>
          <h2><a href="/graphql_demo">See graphql demo here</a></h2>
				</Box>
				{/* <Button href={doLogin('signin', getReturnPage("/participation"))} variant="contained" size="large">SIGN IN</Button> */}
				{/* &nbsp;&nbsp;<a href="/login_demo">LOGIN TEST</a><div></div> */}
				{/* <br/>&nbsp;<br/> */}
				{/* <div>&nbsp;&nbsp;<a href="/file_upload_download_demo">FILE UPLOAD/DOWNLOAD DEMO</a></div> */}
			</LayoutContainer>
		</>
	);
};

export default Home;
