import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'

import { apolloClientOpen } from 'components/authorization/GetAccessRights'
import { LOOKUP_REGISTRATION } from 'Graphql/Registrations/QueryRegistrations'
import { CREATE_REGISTRATION } from 'Graphql/Registrations/MutateRegistrations'

const GraphQlDemo = () => {
  const [getRegistration, { data: lookupRegistrationResponse }] = useLazyQuery(LOOKUP_REGISTRATION, {client: apolloClientOpen});
  const [saveRegistration, { data: saveRegistrationResponse }] = useMutation(CREATE_REGISTRATION, {client: apolloClientOpen});

	const newReg = {
	  "registration": {
	    "CrmId": "" + Math.floor(Math.random() * 10000000000), // generate random 10 digit number
	    "Email": "test2@test.com",
	    "Phone": "+13105551212",
	    "FirstName": "Two",
	    "LastName": "Bruin",
	    "Guests": [
	      {"FirstName": "Guest", "LastName": "NumberOne"},
	      {"FirstName": "Guest", "LastName": "NumberTwo"}
	    ]
	  }
	};

	const doRegistrationLookup = (crmId:string) => {
		getRegistration({variables: { crm_id: crmId }})
	};

	const doSaveRegistration = () => {
		saveRegistration({variables: newReg})
	};

  return (
		<div style={{padding: "20px"}}>
			<Typography variant="h1">Events RSVP GraphQl demo page</Typography>
      <h2><a href="/login_demo">See login demo here</a></h2>
      <h2><a href="/public_id_demo?pid=42EDE97E-3767-49F7-96CE-998DCC331CA7">See personalized link demo here</a></h2>

			<br/>
			<button onClick={() => doRegistrationLookup('1111111111')}>Lookup Registration Demo</button>

			{lookupRegistrationResponse &&
				<pre>{JSON.stringify(lookupRegistrationResponse,null,2)}</pre>}

			<br/>
			<br/>
			<button onClick={doSaveRegistration}>Save New Registration Demo</button>

			{saveRegistrationResponse &&
				<>
					<p>Input:</p>
					<pre>{JSON.stringify(newReg,null,2)}</pre>
					<p>Response:</p>
					<pre>{JSON.stringify(saveRegistrationResponse,null,2)}</pre>
				</>}
		</div>
	);
};

export default GraphQlDemo;

