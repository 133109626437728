import { doSignout } from "pages/temp_demo/login_demo/LoginLandingPageDemo";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
// import { EventRegProfile } from '../../pages/event/Event';

// Need to track if the user is logged in or not and if the profile has changed in order to update the UI via useEffect
type AuthState = {
  isLoggedIn: boolean | undefined;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  lsProfile: string;
  setLsProfile: any;
};

export const AuthContext = createContext<AuthState | undefined>(undefined);
export const useAuth = () => {
  const context = useContext(AuthContext);

  useEffect(() => {
    console.debug("useAuth() isLoggedIn: ", context?.isLoggedIn);
  }, [context?.isLoggedIn]);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};

// Create a provider component for the AuthContext
type AuthProviderProps = {
  children: ReactNode;
};

function localStorageExpiration(hours = 24) {
  const now = new Date();

  const expiration = new Date(now.getTime() + hours * 1000 * 60 * 60);

  return expiration;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { id } = useParams();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>(undefined);
  const [lsProfile, setLsProfile] = useState<any>(
    localStorage.getItem("uclaeventregprofile") || ""
  );

  // console.debug("AuthProvider lsProfile: ");
  // console.debug(lsProfile);
  // Initialize an empty version of EventRegProfile
  // Scaffolding for when we
  // const emptyProfile: EventRegProfile = {
  //   constituentLookupId: "",
  //   emails: [],
  //   phones: [],
  //   names: [],
  //   addresses: []
  // };
  const uclaEventUserLoggedIn: any = localStorage.getItem("uclacidmdata");
  const uclaEventRegProfile: any =
    localStorage.getItem("uclaeventregprofile") || "";
  
  
  const ls_expiry = localStorage.getItem("uclacidmdata_expiry");
  const ls_expiry_date = ls_expiry ? new Date(ls_expiry) : null;
  
  const d = new Date()
  const ls_expired = ls_expiry_date ? d > ls_expiry_date : true

  console.debug("AuthProvider expiry passed: " + ls_expired);
  console.debug(`AuthProvider expiry info: ${d}, ${ls_expiry_date}, ${ls_expired}`);
  if (localStorage.getItem("uclacidmdata_expiry")) {
    if (ls_expired) {
      doSignout(setIsLoggedIn, setLsProfile, `/`);
    }
  }

  useEffect(() => {
    if (uclaEventUserLoggedIn && JSON.parse(uclaEventUserLoggedIn)["success"]) {
      // if (uclaEventRegProfile && Object.keys(JSON.parse(uclaEventRegProfile)).length !== 0) {
      setIsLoggedIn(true);

      const lsExpiration = localStorage.getItem("uclacidmdata_expiry") || "";

      if (!lsExpiration) {
        localStorage.setItem(
          "uclacidmdata_expiry",
          localStorageExpiration(1).toString()
        );
      }
      // setLsProfile(uclaEventRegProfile);
    } else {
      setIsLoggedIn(false);
    }
    setLsProfile(uclaEventRegProfile);
  }, [lsProfile]);

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, setIsLoggedIn, lsProfile, setLsProfile }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
