import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'

import { doApi } from "components/utilities/Api";
import { getCookie, deleteCookie } from 'components/authorization/Utilities';

const PersonalizedLinkDemo = (props:any) => {
  const [apiResults, setApiResults] = useState("")
  const [searchParams] = useSearchParams();

  const publicId = searchParams.get("pid")

  useEffect(() => {
    if (publicId) {
      const getPublicIdData = async () => {
        const apiResponse = await doApi({route: 'publicid?pid=' + publicId})
        setApiResults(JSON.stringify(apiResponse,null,2))
      }
      getPublicIdData()
   }
  }, [])

  const doTimeout = (ms:number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  return (
		<div style={{padding: "20px"}}>
			<Typography variant="h1">Events RSVP Personalized Link demo page</Typography>
      <h2><a href="/graphql_demo">See graphql demo here</a></h2>
      <h2><a href="/login_demo">See login demo here</a></h2>
			<br/>

      <h3>Retrieved Public ID Data</h3>
      <textarea id="userdata" rows={20} cols={60} value={apiResults}></textarea>
		</div>
	);
};

export default PersonalizedLinkDemo;

