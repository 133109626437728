import React, { useContext, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { SettingsContext } from '../components/authorization/GetAccessRights';

import { Divider, Grid } from "@mui/material";
import ExternalNav from "../components/navigation/ExternalNav";
import GlobalNav from "../components/navigation/GlobalNav";
import Footer from "../components/navigation/Footer";
import AdminNav from "../components/navigation/AdminNav";
import AdditionalLinks from "../components/navigation/AdditionalLinks";

// import { GoToLogin } from '../components/authorization/LoginLogout'

import { useLoginStatus } from '../components/authorization/LoginLogout'
import { getLoggedInUser, localStorageLoggedInUser, doLogout } from "../components/authorization/Utilities"
import LoadingSpinner from "../components/progress/Spinner";

import { hasData } from '../components/utilities/Validation'
import NotAnASCUser from "./error/NotAnASCUser";
import ScrollToTop from "../components/utilities/ScrollToTop";

/* ********************************************************
Layout Wraps around all page components
Outlet represents the child components (Page Body) referenced 
in the Routes in index.ts

This is also where the re-routing conditionals reside.
eg) If profile not filled out, redirect from participation to user profile

*********************************************************** */
const HOME_PATHNAME = "/"

const Layout = (props: any) => {
  const loginStatusInfo = useLoginStatus();
  const loginStatus = loginStatusInfo.loginStatus
  // const loginUserProfile = loginStatusInfo.userProfile

  const settings: any = useContext(SettingsContext)
  // const lsLoggedInUser = localStorageLoggedInUser();
  const lsLoggedInUser = getLoggedInUser(settings);
  // const lsLoggedInUserId = lsLoggedInUser && lsLoggedInUser.id ? lsLoggedInUser.id : "";
  const lsLoggedOut = localStorage.getItem('loggedOut');
  const lsLoginSessionTimedOut = localStorage.getItem('loginSessionTimedOut');
  const lsLoginFailed = localStorage.getItem('loginFailed');
  const lsUclaeaidmtoken = localStorage.getItem("uclaeaidmtoken")
  const location = useLocation();

  let profileFilled = hasData(settings.accessRights) ? settings.profileFilled : false
  if (profileFilled === false) {
    profileFilled = lsLoggedInUser ? lsLoggedInUser.profile_filled_out : false
  }

  if (location.pathname === '/logout') {
    return <Outlet />
  }

  // Home.tsx calls GlobalNav. Just render
  if (['/public_id_demo','/login_demo','/graphql_demo','/'].indexOf(location.pathname) > -1) {

    return (
      <>
        {/* <ExternalNav />
        {lsLoggedInUser && <GlobalNav /> } */}
          <Outlet />
        {/* <AdditionalLinks /> */}
        {/* <Footer /> */}
      </>
    )
  }

  // if (location.pathname !== HOME_PATHNAME && !lsLoginFailed && (lsLoggedOut || lsLoginSessionTimedOut || !lsLoggedInUserId) ) {
  if (location.pathname !== HOME_PATHNAME && !lsLoginFailed && (lsLoggedOut || lsLoginSessionTimedOut) ) {
    window.location.href = HOME_PATHNAME
    return <></>
  }
  


  // add the spinner state in here
  if (loginStatus === 'waiting') {
    return <LoadingSpinner />
  }

  if (lsUclaeaidmtoken && (lsLoginFailed || loginStatus === 'failed')  ) {
    // if you're going to remember the route the user is trying to go to, it might make more sense to put that here
    // ideally the participation page shouldn't know it's the home page or have any special logic assuming the user will land on it
    // since at some point they may want to change the initial landing page
    console.debug("LAYOUT - login failed: ", loginStatus, lsLoggedInUser)
    return (
      <>
        <ExternalNav />
        <NotAnASCUser />
      </>
    )
    // return <GoToLogin returnRoute="/participation"/> //Redirect back to login
  }
  
  if (location.pathname !== HOME_PATHNAME && !lsUclaeaidmtoken &&  (lsLoginFailed || loginStatus === 'failed')) {
    window.location.href = HOME_PATHNAME
    return <></>
  }

  // console.debug('LAYOUT - lsLoggedInUser', lsLoggedInUser)
  // console.debug("LAYOUT - settings: ", settings, lsLoggedInUser, profileFilled)
  // console.debug("LAYOUT - login status: ", loginStatus)
  // console.debug("LAYOUT - loginUserProfile: ", loginUserProfile)

  // console.debug('Layout')
  // console.debug('lsLoggedInUser', lsLoggedInUser)
  // console.debug('context settings:', settings)
  // console.debug('loginStatus:', loginStatus)
  // console.debug('profileFilled:', profileFilled)
  // console.debug('location pathname: ', location.pathname)
  // Stephen: this first block should be how to handle pages that don't require login, I think it's safe to say there will be at least one at some point
  // there is probably a cleaner way than just looking at the location.pathname (like some property on the page component itself that indicates that page doesn't require login)
  // For some reason SettingsContext.Provider and GetAccessRights were causing it to attempt logged in calls

  // console.debug('location: ', location)
  // if (!lsLoggedInUser) {
  //   window.location.href = HOME_PATHNAME
  // }

  // if (profileFilled === false && location.pathname === '/participation' ) {
  //   return <></>
  // }

  if (profileFilled === false && location.pathname !== '/profile' ) {
    // console.debug('REDIRECT Non-Admin to Profile')
    window.location.href = "/profile"
    return <></>
  }

  if (settings.isLoading) {
    return <></>
  }

  return (
    <>
      <ScrollToTop />
      <ExternalNav />
      <GlobalNav />
        <Outlet />
      <AdditionalLinks />
      <Footer />
    </>
  );
}

export const AdminLayout = () => {
  const loginStatusInfo = useLoginStatus();
  const loginStatus = loginStatusInfo.loginStatus
  // const loginUserProfile = loginStatusInfo.userProfile
  const lsLoggedOut = localStorage.getItem('loggedOut');
  const lsLoginSessionTimedOut = localStorage.getItem('loginSessionTimedOut');
  const lsLoginFailed = localStorage.getItem('loginFailed');
  const lsUclaeaidmtoken = localStorage.getItem("uclaeaidmtoken")

  const settings: any = useContext(SettingsContext)
  // const lsLoggedInUser = localStorageLoggedInUser();
  const lsLoggedInUser = getLoggedInUser(settings);
  // console.debug('AdminLayout: ', lsLoggedInUser)
  // const lsLoggedInUserId = lsLoggedInUser && lsLoggedInUser.id ? lsLoggedInUser.id : "";

  const location = useLocation();

  let profileFilled = hasData(settings.accessRights) ? settings.profileFilled : false
  if (profileFilled === false) {
    profileFilled = lsLoggedInUser ? lsLoggedInUser.profile_filled_out : false
  }

  // console.debug('AdminLayout')
  // console.debug('lsLoggedInUser', lsLoggedInUser)
  // console.debug('settings.isLoading:', settings.isLoading)
  // console.debug('context settings:', settings)
  // console.debug('profileFilled:', profileFilled)
  
  if (loginStatus === 'waiting') {
    return <LoadingSpinner />
  }
  
  if (lsUclaeaidmtoken && (lsLoginFailed || loginStatus === 'failed')  ) {
    console.debug("LAYOUT - ADMINNAV failed: ", loginStatus, lsLoggedInUser)
    return (
      <>
        <ExternalNav />
        <NotAnASCUser />
      </>
    )
    // return <GoToLogin returnRoute="/participation"/>  //Redirect back to login
  }
  
  // if (location.pathname !== HOME_PATHNAME && !lsUclaeaidmtoken &&  (lsLoginFailed || loginStatus === 'failed' || !lsLoggedInUserId)) {
  if (location.pathname !== HOME_PATHNAME && !lsUclaeaidmtoken && ((lsLoggedOut || lsLoginSessionTimedOut) || (lsLoginFailed || loginStatus === 'failed'))) {
    window.location.href = HOME_PATHNAME
    return <></>
  }

  // console.debug('location: ', location)
  

  // if (!lsLoggedInUser) {
  //   // console.debug('REDIRECT to Home')
  //   window.location.href = HOME_PATHNAME
  // }

  // if (settings.isLoading === false && location.pathname !== '/profile' ) {
  if (profileFilled === false && location.pathname !== '/profile' ) {
    console.debug('REDIRECT Admin to Profile')
    window.location.href = "/profile"
    return <></>
  }

  if (settings.isLoading) {
    return <></>
  }

  return (
    <>
      <ScrollToTop />
      <ExternalNav />
      <GlobalNav />
      <Grid container>
        <Grid item xs="auto" sx={{ overflow: 'hidden', }}>
          <AdminNav  />
        </Grid>
        <Grid item xs component="main" sx={{ p: 6, }}>
          <Outlet />
        </Grid>
      </Grid>
      <Divider />
      <Footer />
    </>
  );
}

export default Layout;