import React, { useEffect, useState, createContext } from 'react'
import { useLocation } from "react-router-dom";

import { ApolloClient, InMemoryCache, useLazyQuery } from '@apollo/client'
// GET Route Access
import { hasData } from '../utilities/Validation'
import { GET_ROUTE_ACCESS } from '../../Graphql/Authorization/QueryAuthorization';

import { localStorageLoggedInUser } from "../../components/authorization/Utilities"
import { useLoginStatus } from '../../components/authorization/LoginLogout'

export const SettingsContext = createContext({});

export const apolloClientOpen = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URL_OPEN ?? 'http://localhost:4001/graphql-open',
  cache: new InMemoryCache(),
});

export const GetAccessRights = ({children}: any) => {
  
  const [ getSettingsData, { loading, error, data }] = useLazyQuery(GET_ROUTE_ACCESS);

  useEffect(() => {
    if(queryAccessRights()) {
      console.debug("GetAccessRights - queryAccessRights", queryAccessRights())
      getSettingsData()
    }

  }, [getSettingsData])

  function queryAccessRights() {
    // Bypass querying for Access rights if on a spacific page.
    const location_pathname = window.location.pathname
    if(location_pathname !== "/" && 
        ['/mll','/graphql_demo','/login_demo'].indexOf(location_pathname) === -1
      ) {
      // console.debug('Query Access Rights')
      return true
    }
    console.debug(`"${location_pathname}" -> Do not get Access Rights`)
    return false
  }

  const accessRightsData = data && hasData(data) && data.getAccessRights.route_permissions ? data.getAccessRights.route_permissions : {}
  const profileFilled = data && hasData(data) && data.getAccessRights.profile_filled_out ? data.getAccessRights.profile_filled_out : false
  const academicYearSettingsData = data && hasData(data) && data.getSettingsCurrentAcademicYear.length > 0 ? data.getSettingsCurrentAcademicYear[0] : {}

  // if (loading) {
  //   console.log('Loading TEST: GetAccessRights Data', accessRightsData, loading, profileFilled)
  // }
  // if (!loading && hasData(accessRightsData)) {
  //   console.debug('GetAccessRights - !Loading TEST: GetAccessRights Data', accessRightsData, loading, profileFilled, academicYearSettingsData)
  // }
  
  const value = { isLoading: loading, accessRights: accessRightsData, academicYearSettings: academicYearSettingsData, profileFilled: profileFilled, loggedInUserId: localStorageLoggedInUser() ? localStorageLoggedInUser().id : ""}
  
  if (children) {
    return (
      <SettingsContext.Provider value={value}>
        {children(value)}
      </SettingsContext.Provider>
    )
  }
  return <></>
}