
// called when any user loads home page, to wake up the lamdba
export const doPing = async () => {
  const pingResponse = await doApi({route: `graphql-open`});
  console.log({pingResponse})
  return
};

/// API ///
export const doApi = async (options:any) => {
  console.log('doApi!!!', options);

  let fetchUrl = process.env.REACT_APP_GATEWAY_HOST + options.route;

  const fetchData = {
    method: options.method || "GET",
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: options.token || localStorage.getItem("uclaeaidmtoken") || '',
    },
    body: options.data ? JSON.stringify(options.data) : null
  }

  try {
    const response = await fetch(fetchUrl, fetchData);
    const data = await response.json();
    return data;
  }
  catch (e:any) {
    if (options.noError) return;

    console.log("ERROR - Failed to ' + fetchData.method + ' ' + route");
    console.log(e);
    return {
      error: {
        key:e.key || 'CALL_FAILED',
        msg:e.message || e,
      }
    };
  }
};

