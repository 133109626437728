import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
// import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { useParams } from "react-router-dom";
import { doApi } from "components/utilities/Api";
import { getCookie, deleteCookie } from 'components/authorization/Utilities';
import { useAuth } from 'components/authorization/AuthContext';
import { EventRegProfile } from 'pages/event/Event';


const testCookie = "eyJraWQiOiJDSmNnak5JOEllUkxoZzJwVnpwUFNGXC8zMEgxbmZYaUpKeFwvZ042SzBcL0ZnPSIsImFsZyI6IlJTMjU2In0.eyJhcHAiOiJFVkVOVFMiLCJzdWIiOiI3OWE2OTFkNC0zNmM4LTQ1NjctYmZiYy1jNWU2MTNhZTJlMzgiLCJjb2duaXRvOmdyb3VwcyI6WyJBdWN0aW9uQWRtaW5Vc2VycyJdLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy13ZXN0LTIuYW1hem9uYXdzLmNvbVwvdXMtd2VzdC0yX2NiaXRDdWRyVCIsInBob25lX251bWJlcl92ZXJpZmllZCI6dHJ1ZSwiY29nbml0bzp1c2VybmFtZSI6Ijc5YTY5MWQ0LTM2YzgtNDU2Ny1iZmJjLWM1ZTYxM2FlMmUzOCIsImVudiI6ImRldiIsImRvRHVwZUNoZWNrIjoiZmFsc2UiLCJjdXN0b206QmxhY2tCYXVkSWQiOiIwMTIzNDU2Nzg5Iiwib3JpZ2luX2p0aSI6ImVkYTM3NjZiLWRhNmEtNGE3Zi1iMWE0LWY5NGM3M2FjYWZhOSIsImF1ZCI6IjMwOTg2czlmaGY1MjF0c3RxNTU4MHZyYjU5IiwiY29nbml0b0xvZ2luIjoidGVzdEB0ZXN0LmNvbSIsImV2ZW50X2lkIjoiMDc5ZDVkNTMtNmQ3NS00ZWE1LThmOGItOGJmNDFiM2M5MzlkIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE3MTI4MTQzMDAsImZhaWxNc2ciOiIiLCJwaG9uZV9udW1iZXIiOiIrMTk3MjU2NzIyNTkiLCJleHAiOjE3MTI5MDA3MDAsImlhdCI6MTcxMjgxNDMwMCwianRpIjoiNzExYWIwMTUtODk5OC00Y2RlLThlZWEtYTU2MTQ1YTU4MzExIiwiZW1haWwiOiJ0ZXN0QHRlc3QuY29tIn0.lUQjsDToWVU_w_RHv88_OyvwvT5t-2Zq7O7h-Ji9QYN8Bt9qHlpJGdY89rZyzoFTrbFg30eIE9t-sjYByHTNDg7PcIab_0kAQYl4trzKj4Zy8G9Maq6Yf-h8TaLj3lRDjR5aNKnfxj3Y2kgrL3AQBThWjO9rmwOOUmM-86LMzBJP2ibPwgUJqt_tE53m26yPAsAZFBMceVVHSWPCA4oEzSRvxuBUzUHO6R_kJR-t1AHiWA5DdShU0E4tIeZ5qXFLka7iD4waNIqzyg7OsAf4lnDSF_Hh7XxBBnfkzQPgWeIF5vkErmvlRZ_IvthYLk3vFAZQrRAn5W39t3mNLh1Vsw"
// const testCookie = "eyJraWQiOiJDSmNnak5JOEllUkxoZzJwVnpwUFNGXC8zMEgxbmZYaUpKeFwvZ042SzBcL0ZnPSIsImFsZyI6IlJTMjU2In0.eyJhcHAiOiJFVkVOVFMiLCJzdWIiOiIxNzg2MWQ5NS0wNWI5LTQ1ZTMtYjNjMy0yNTIyMWEyMWZiYTgiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLXdlc3QtMi5hbWF6b25hd3MuY29tXC91cy13ZXN0LTJfY2JpdEN1ZHJUIiwiY29nbml0bzp1c2VybmFtZSI6IjE3ODYxZDk1LTA1YjktNDVlMy1iM2MzLTI1MjIxYTIxZmJhOCIsImVudiI6ImRldiIsImRvRHVwZUNoZWNrIjoiZmFsc2UiLCJjdXN0b206QmxhY2tCYXVkSWQiOiIiLCJvcmlnaW5fanRpIjoiODk4NjI4YjAtYTBlZi00MGY3LTgzNWUtMjlmMzBjOTFlN2NlIiwiYXVkIjoiMzA5ODZzOWZoZjUyMXRzdHE1NTgwdnJiNTkiLCJjb2duaXRvTG9naW4iOiJ0ZXN0YXBwZGV2MzZAZ21haWwuY29tIiwiZXZlbnRfaWQiOiJkMTgyNTkyYS00Yjk5LTRiY2EtYjg0Yi0zYTJmMDExN2I1ZWYiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTcxMTUyNDQyOCwiZmFpbE1zZyI6Ik5PX0NSTV9VU0VSU19GT1VORCIsImV4cCI6MTcxMTYxMDgyNywiaWF0IjoxNzExNTI0NDI4LCJqdGkiOiI2MWVhZjYwYi05MTE2LTRhMTktOWJiNy1kMDI0YTJhNWExN2MiLCJlbWFpbCI6InRlc3RhcHBkZXYzNkBnbWFpbC5jb20ifQ.OEvtwQx6JlNl4CWGqN8B_S8NBVA4NhN3Jk5Ub7MPlOExL0olHoRAb7vXBYG5K64u1BEyU9_bH-kz814MpXF1OEfDWHUFKf00rTZGIXJ5tWI9850KjPyUDnGZB5eeTnC-ntW_FJp3dPR42psIr95W0Qwx-oew1Ap4u_dkwX_Y7yxgz0LIkvus4o8x7-jGlIQLW2KRQn5MMEUk-6g4bafg_vqi6YejIbzG5gW1hrAUJXSXiPFatnOqALLnb6i1UrNKj5iu9UwDi5_usObF0yUR4NttGFkpQoH8-vXOGzZaaP835u0fs5d0l_hebT6tIUb534VbGUUx2-Iqb_NrAWjJ7Q"

export const windowPopupCidmLogin = (path:String, setIsLoggedIn?: any, setLsProfile?: any,
  setApiResults?: any) => {

  localStorage.removeItem("uclaeaidmtoken")
  localStorage.removeItem("uclacidmdata")
  // const popup = window.open("http://local.account.giving.ucla.edu:3000/" + path);
  const popup = window.open("https://dev.account.eventsrsvp.support2.ucla.edu/" + path);
  if (!popup) {
    return alert('POPUP_FAILED');
  }
  const pollTimer = window.setInterval(function() {
    if (popup.closed !== false) { // !== is required for compatibility with Opera
      window.clearInterval(pollTimer);
      checkForNewData(setIsLoggedIn, setLsProfile, setApiResults);
    }
  }, 1000);
};

async function checkForNewData(setIsLoggedIn?: any, setLsProfile?: any, setApiResults?: any) {
  console.log('sign in window closed')
  const cookie = getCookie('.UCLAEAIDMID');
  processLogin(cookie || testCookie, setIsLoggedIn, setLsProfile, setApiResults);
}

export function doSignout(setIsLoggedIn?: any, setLsProfile?: any, redirect="/", setApiResults?: any) {
  deleteCookie('.UCLAEAIDMID', '/', 'ucla.edu');
  localStorage.clear();

  if (setIsLoggedIn) {
    setIsLoggedIn(false);
  }
  // if (setLsProfile) {
  //   setLsProfile('');
  // }
  if (setApiResults) {
    setApiResults('');
  }
  window.location.href = redirect
};

async function processLogin(token:any, setIsLoggedIn?: any, setLsProfile?: any, setApiResults?: any) {
  // user is logged in
  
  
  let dots
  console.debug('processLogin token:', token )
  if (token) {
    if (setApiResults) {
      setApiResults('LOADING');
      dots = setInterval(() => { setApiResults((prev: string) => prev + '.')}, 500);
    }

    localStorage.setItem("uclaeaidmtoken", token)
    const loggedInUserData = await doApi({token, route: 'profile'});
    // await doTimeout(5000)

    const loggedInUserDataJson = JSON.stringify(loggedInUserData,null,2)
    
    let loggedInUserDataBasicProfile: EventRegProfile = {
      "constituentLookupId": "",
      "emails": [],
      "phones": [],
      "names": [],
      "addresses": []   
    }



    // if (loggedInUserData.message && loggedInUserData.message === "The incoming token has expired") {

    if (loggedInUserData.message || loggedInUserData.error) {
      setIsLoggedIn(false);
      localStorage.setItem("uclacidmdata", "")
      loggedInUserDataBasicProfile["constituentLookupId"] = "NO CRM ID FOUND"

    } else {
      loggedInUserDataBasicProfile = {
        "constituentLookupId": loggedInUserData.success?.constituent.constituentLookupId || "NO CRM ID FOUND",
        "emails": loggedInUserData.success?.constituent.emails || [],
        "phones": loggedInUserData.success?.constituent.phones || [],
        "names": loggedInUserData.success?.constituent.names || [],
        "addresses": loggedInUserData.success?.constituent.addresses || []   
      }

      setIsLoggedIn(true);
    }

    localStorage.setItem("uclacidmdata", loggedInUserDataJson)
    localStorage.setItem("uclaeventregprofile", JSON.stringify(loggedInUserDataBasicProfile))

    setLsProfile(JSON.stringify(loggedInUserDataBasicProfile))

    console.debug('processLogin loggedInUserDataJson:', loggedInUserData)

    if (setApiResults) {
      clearInterval(dots);
      setApiResults(loggedInUserDataJson);
    }
  }
  // user is not logged in
  if (!token) {
    setIsLoggedIn(false);
    if (setApiResults) {
      setApiResults('NOT LOGGED IN');
    }
    // localStorage.clear();
    localStorage.setItem("uclacidmdata", "")
    // localStorage.setItem("uclaeventregprofile", JSON.stringify(loggedInUserDataBasicProfile))
  }
}

const LoginLandingPageDemo = () => {
  //SNG added Auth context hook. See index.tsx where <AuthProvider/> wraps around the main app components
  const { isLoggedIn, setIsLoggedIn, setLsProfile } = useAuth(); 
  const [apiResults, setApiResults] = useState("")
  // const [signedIn, setSignedIn] = useState(false)

	const newReg = {
	  "registration": {
	    "CrmId": "" + Math.floor(Math.random() * 10000000000), // generate random 10 digit number
	    "Email": "test2@test.com",
	    "Phone": "+13105551212",
	    "FirstName": "Two",
	    "LastName": "Bruin",
	    "Guests": [
	      {"FirstName": "Guest", "LastName": "NumberOne"},
	      {"FirstName": "Guest", "LastName": "NumberTwo"}
	    ]
	  }
	};

  useEffect(() => {
    if (isLoggedIn) {
      // Perform actions when user is logged in
      setApiResults(localStorage.getItem("uclacidmdata") || "")
    } else {
      setApiResults('NOT LOGGED IN');
    }
  }, [isLoggedIn]);

  const doTimeout = (ms:number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  return (
		<div style={{padding: "20px"}}>
			<Typography variant="h1">Events RSVP Login demo page</Typography>
      <h2><a href="/graphql_demo">See graphql demo here</a></h2>
      <h2><a href="/public_id_demo?pid=42EDE97E-3767-49F7-96CE-998DCC331CA7">See personalized link demo here</a></h2>
			<br/>

      {!isLoggedIn &&
      // {!signedIn &&
        <>
          <button id="signinpopupbutton" onClick={() => windowPopupCidmLogin('signin', setIsLoggedIn, setLsProfile, setApiResults)}>SIGN IN</button>
          <br/>
        </>}

      {isLoggedIn &&
      // {signedIn &&
        <>
          <button id="profilepopupbutton" onClick={() => windowPopupCidmLogin('profile', setIsLoggedIn, setLsProfile, setApiResults)}>EDIT PROFILE</button>
          <br/>
          <br/>
          <button id="signoutbutton" onClick={() => doSignout(setIsLoggedIn, setLsProfile, "/", setApiResults)}>SIGN OUT</button>
        </>}

      <br/>
      <h3>Logged In User Data</h3>
      <textarea id="userdata" rows={20} cols={60} value={apiResults}></textarea>
		</div>
	);
};

export default LoginLandingPageDemo;

